import React from "react"
import styled from "styled-components"
import { Container, Section } from "../global"
import { navigate } from "gatsby"

const SupportDay = (props) => (
  <Section style={{ paddingTop: "20px" }}>
    <GetStartedTitle>Support {props.state.username}</GetStartedTitle>
    <GetStartedSubTitle>
      {props.state.username == "Dailyness & Bitcoin"
        ? `Like what you see? Support our community with a monthly contribution!`
        : `Like what you see? Show your support for ${props.state.username}'s daily
      practice!`}
    </GetStartedSubTitle>
    <StyledContainer>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>$4.99/mo</FeatureTitle>
          <FeatureText>
            <HeaderButton
              onClick={() => {
                navigate(`https://buy.stripe.com/bIY4jMaNqcai8ne289`)
              }}
            >
              Support
            </HeaderButton>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>$14.99/mo</FeatureTitle>
          <FeatureText>
            <HeaderButton
              onClick={() => {
                navigate(`https://buy.stripe.com/fZedUmaNq3DMavm288`)
              }}
            >
              Support
            </HeaderButton>
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>$49.99/mo</FeatureTitle>
          <FeatureText>
            <HeaderButton
              onClick={() => {
                navigate(`https://buy.stripe.com/8wM3fI6xa1vE6f6cMO`)
              }}
            >
              Support
            </HeaderButton>
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
    <GetStartedMiniTitle>
      Supporting is easy. No account or typing required.
    </GetStartedMiniTitle>
    <FeatureItem>
      <FeatureTitle>Donate Bitcoin</FeatureTitle>

      <FeatureText>
        <img
          style={{
            maxHeight: "200px",
            width: "auto",
            position: "relative",
            top: "5px",
            marginRight: "3px",
          }}
          alt="Donate Bitcoin"
          src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Fdonate-btc.png?alt=media&token=b5af371e-ce35-4206-af8d-fd0cea4f829a"
        />
      </FeatureText>
    </FeatureItem>
  </Section>
)

export default SupportDay

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  justify-content: center;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 870px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 30px;
  padding-bottom: 60px;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const IntentionItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0px;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const IntentionTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin: 0px;
  margin-top: 10px;
  padding: 0px;
  float: left;
`

const FeatureText = styled.p`
  text-align: center;
`
const IntentionText = styled.p`
  text-align: left;
  float: left;
  margin: 0px;
  padding: 0px;
  margin-top: 5px;
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`
const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const GetStartedTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const GetStartedSubTitle = styled.div`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto 40px;
  text-align: center;
`

const GetStartedMiniTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
`
