import React, { useState, useEffect } from "react"
import styled from "styled-components"
import appstore from "../../images/appstore.png"
import { Container, Section } from "../global"
import google from "../../images/google.png"
import AnimatedNumber from "react-animated-number"
import firebase from "gatsby-plugin-firebase"
import intention from "../../images/btc-meditation.png"

const ProofOfWork = () => {

  const [totalDays, setTotalDays] = useState(0)

  useEffect(() => {
    firebase
      .firestore()
      .collection("days")
      .get()
      .then((querySnapshot) => {
        setTotalDays(querySnapshot.docs.length) //get total number of days
      })
      .catch((error) => {})
  }, [])

  return (
    <Section id="features">
      <StyledContainer>
        <Subtitle>Proof of Work</Subtitle>
        <SectionTitle>
          <AnimatedNumber
            style={{
              transition: ".8s ease-out",
              transitionProperty: "background-color, color",
            }}
            frameStyle={(perc) =>
              perc === 100 ? {} : { backgroundColor: "#FFDE59" }
            }
            stepPrecision={0}
            value={totalDays}
            duration={12000}
            formatValue={(n) => n + "  Days Completed"}
          />{" "}
        </SectionTitle>
        <FeaturesGrid>
          <FeatureItem>
            <FeatureTitle>
              <img
                style={{
                  maxHeight: "30px",
                  width: "auto",
                  position: "relative",
                  top: "5px",
                  marginRight: "3px",
                }}
                alt="Proof of Work"
                src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Ficons%2Fcommunity.png?alt=media&token=8acbf6be-963b-4d61-b281-00b503cbe245"
              />
              We Do The Work
            </FeatureTitle>

            <FeatureText>
              Day members have completed {totalDays} days. Support our community as we
              spread dailyness & Bitcoin!
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <img
              style={{
                maxHeight: "200px",
                width: "auto",
              }}
              alt="Feature"
              src={
                intention
              }
            />
          </FeatureItem>
        </FeaturesGrid>
      </StyledContainer>
    </Section>
  )
}

export default ProofOfWork

const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 870px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin: 4px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
